import React from "react";
import clsx from "clsx";

import { Container, Text, Image, Icon } from "@atoms";

const TestimonialBlock = ({
  quote,
  name,
  position,
  image,
  reverse,
  className: _className,
}) => {
  return (
    <section className={clsx("my-10 sm:my-20", _className)}>
      <Container padding>
        <div className="flex flex-wrap items-center flex-gap-4 md:flex-gap-20">
          <div
            className={clsx("order-2 w-full md:w-4/7", {
              "md:order-1": !reverse,
            })}
          >
            <div className="relative flex flex-col space-y-4 pl-8">
              <div className="relative">
                <span className="absolute -left-8 top-0 h-auto w-6 text-teal">
                  <Icon name="quotation" className="h-full w-full" />
                </span>
                <Text variant="h5" className="font-bold text-slate">
                  {quote}
                </Text>
              </div>
              <div>
                <Text variant="body" className="text-purple">
                  {name}
                </Text>
                <Text variant="sm" className="text-slate">
                  {position}
                </Text>
              </div>
            </div>
          </div>
          <div
            className={clsx("order-1 w-full md:w-3/7", {
              "md:order-2": !reverse,
            })}
          >
            <div className="relative aspect-[2/1] w-full overflow-hidden rounded-3xl bg-purple shadow-xl md:aspect-[7/6]">
              <Image image={image} fill className="aspect-square rounded-3xl" />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

TestimonialBlock.defaultProps = {
  reverse: false,
};

export default TestimonialBlock;
